import { getCurrentInstance } from "@vue/composition-api";
import VueRouter from "vue-router";

export function useRouter(): VueRouter {
  const instance = getCurrentInstance();

  // `setup()`外で使用するとインスタンスは取得できません
  // https://v3.vuejs.org/api/composition-api.html#getcurrentinstance
  if (!instance) throw new Error(`Should be used in setup().`);

  return instance.proxy.$router;
}
