// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export default function equalsParams(a: any, b: any): boolean {
  if (!a || !b) {
    return a === b;
  }

  const aNames = Object.getOwnPropertyNames(a);
  const bNames = Object.getOwnPropertyNames(b);
  if (aNames.length !== bNames.length) {
    return false;
  }

  for (const name of aNames) {
    if (a[name] !== b[name]) {
      return false;
    }
  }

  return true;
}
