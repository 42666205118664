










import { computed, defineComponent } from "@vue/composition-api";
import { DateTime } from "luxon";

export default defineComponent({
  props: {
    date: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    const dateLuxon = computed(() => {
      if (typeof props.date === "string") return DateTime.fromISO(props.date);
      if (props.date.toString().length === 10) return DateTime.fromSeconds(props.date);
      return DateTime.fromMillis(props.date);
    });

    const displayDate = computed(() => {
      if (!dateLuxon.value) return "エラー";
      if (dateLuxon.value.hasSame(DateTime.now(), "day")) {
        return `今日 ${dateLuxon.value.toLocaleString(DateTime.TIME_24_SIMPLE)}`; // 13:37
      }
      return dateLuxon.value.toLocaleString(DateTime.DATE_SHORT); // 2021/2/25
    });

    // tooltipDate: 2021年2月25日 0:37 JST
    const tooltipDate = dateLuxon.value?.toLocaleString(DateTime.DATETIME_FULL) || "エラー";

    return {
      dateLuxon,
      displayDate,
      tooltipDate,
    };
  },
});
