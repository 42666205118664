























































































import { computed, defineComponent } from "@vue/composition-api";
import { Deal } from "@/models/Deal";
export default defineComponent({
  props: {
    deals: {
      type: Array,
      required: true,
    },
    dealPharmacy: {
      type: Object,
      required: true,
    },
    myPharmacy: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const subTotalPrice = computed(() => (props.deals as Array<Deal>).reduce((p, deal) => p + deal.price, 0));
    const totalTaxPrice = computed(() => calcTaxPrice(subTotalPrice.value));
    const totalPrice = computed(() => calcIncludedTaxPrice(subTotalPrice.value));

    function calcTaxPrice(priceBeforeTax: number): number {
      return Math.floor(priceBeforeTax * 0.1);
    }

    function calcIncludedTaxPrice(priceBeforeTax: number): number {
      return priceBeforeTax + calcTaxPrice(priceBeforeTax);
    }

    return {
      subTotalPrice,
      totalTaxPrice,
      totalPrice,
    };
  },
});
