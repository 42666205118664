

















import ThePublicHeader from "@/containers/ThePublicHeader.vue";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: { ThePublicHeader },
});
