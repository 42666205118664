

















import DealItemView from "@/components/deals/DealItemView.vue";
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  components: { DealItemView },
  props: {
    modalSize: {
      type: String,
      default: "xl",
    },
    thumbnail: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      required: true,
    },
  },
  setup() {
    const isOpenModal = ref<boolean>(false);

    function openModal() {
      isOpenModal.value = true;
    }

    return {
      isOpenModal,
      openModal,
    };
  },
});
