

















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    deal: {
      type: Object,
      required: true,
    },
  },
});
