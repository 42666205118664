



















import SelectPharmacyModal from "@/components/shared/SelectPharmacyModal.vue";
import TheHeader from "@/containers/TheHeader.vue";
import TheSidebar from "@/containers/TheSidebar.vue";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: { TheHeader, TheSidebar, SelectPharmacyModal },
});
