



































































































































import SelectGroups from "@/components/groups/SelectGroups.vue";
import LoadingAlert from "@/components/shared/LoadingAlert.vue";
import { Deal } from "@/models/Deal";
import { useHttpMedshare } from "@/plugins/axios";
import { useRouter } from "@/router/useRouter";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { CCard, CCardBody, CRow } from "@coreui/vue";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import { DateTime } from "luxon";

export default defineComponent({
  components: { LoadingAlert, SelectGroups },
  setup() {
    const router = useRouter();
    const httpMedshare = useHttpMedshare();

    const isFirstLoading = ref<boolean>(true);
    const isLoading = ref<boolean>(true);
    const errors = ref<string[]>([]);

    const selectingPharmacyStore = useSelectingPharmacyStore();
    const myPharmacyId = selectingPharmacyStore.selectingPharmacyId;

    const dealId = router.currentRoute.params.id;
    const dealUrl = computed(() => `/api/v2/pharmacy/pharmacies/${myPharmacyId.value}/deals/${dealId}`);
    const deal = ref<Deal | null>(null);
    const expires_on = ref<string | null>(null);
    const selectedGroupIds = ref<number[]>([]);

    const discountRateTooltip = {
      html: true,
      content: "期限1年以上: 40%<br />半年〜1年: 50%<br />半年以内: 60％<br /><br />ジェネリックはさらに20％加えた率を推奨しております。",
    };

    const shouldDisableSaveButton = computed(() => !deal.value);
    const price = computed(() => {
      return deal.value ? Math.round(tradeUnitPrice.value * deal.value.unit): 0;
    });
    const thisMonth = DateTime.now().toFormat("YYYY-MM");

    const priceBasis = ref<string>(""); 
    const tradeUnitPrice = ref<number>(0);
    const discountRate = ref<number>(0);
    const isChecked = ref(false);

    const updateTradeUnitPrice  = () => {
      const d = deal.value;
      let coefficient = 1
      if (d?.dealable_item.min_drug_price_coefficient) {
        coefficient = d.dealable_item.min_drug_price_coefficient;
      }
      if (d?.dealable_item) {
        // 小数第二位までとする
        tradeUnitPrice.value = parseFloat((d.dealable_item.price * coefficient * (100 - discountRate.value) / 100).toFixed(2));
      }
    }

    const updateDiscountRate  = () => {
      const d = deal.value;
      let coefficient = 1
      if (d?.dealable_item.min_drug_price_coefficient) {
        coefficient = d.dealable_item.min_drug_price_coefficient;
      }
      if (d?.dealable_item) {
        // 整数とする
        discountRate.value = Math.round((1 - (tradeUnitPrice.value / (d.dealable_item.price * coefficient))) * 100);
      }
    }

    const unitName = computed(() => {
      return deal.value?.dealable_item.unit_name ? "/" + deal.value.dealable_item.unit_name : "";
    })

    async function load() {
      if (!myPharmacyId.value) return;
      const response = await httpMedshare.get(dealUrl.value);

      deal.value = new Deal(response.data);
      expires_on.value = DateTime.fromISO(deal.value.expires_on).toFormat("yyyy-MM");
      selectedGroupIds.value = deal.value.groups?.map((g) => g.id) || [];

      priceBasis.value = deal.value.price_basis ? deal.value.price_basis : "discount_rate";

      let coefficient = 1;
      if (deal.value.dealable_item.min_drug_price_coefficient) {
        coefficient = deal.value.dealable_item.min_drug_price_coefficient;
      }

      if (priceBasis.value === "trade_unit_price") {
        tradeUnitPrice.value = deal.value.trade_unit_price ? deal.value.trade_unit_price : 0;
        if (deal.value.dealable_item) {
          discountRate.value = Math.round((1 - (tradeUnitPrice.value / (deal.value.dealable_item.price * coefficient))) * 100);
        }
        isChecked.value = true;
      } else {
        if (deal.value.dealable_item) {
          tradeUnitPrice.value = parseFloat((deal.value.dealable_item.price * coefficient * (100 - deal.value.discount_rate) / 100).toFixed(2));
        }
        discountRate.value = deal.value.discount_rate;
      }

      isFirstLoading.value = false;
      isLoading.value = false;
    }

    function changePriceBasis(value: string) {
      priceBasis.value = value;
    }

    function changeUnpacked(value: boolean) {
      if (deal.value) deal.value.unpacked = value;
    }

    async function save() {
      errors.value = [];

      try {
        if (deal.value === null) return;

        const expiresOn = (() => {
          if (!expires_on.value) {
            return null;
          } else {
            return DateTime.fromFormat(expires_on.value, "yyyy-MM").endOf("month").toFormat("yyyy-MM-dd");
          }
        })();
        if (!deal.value.dealable_item) errors.value.push("医薬品を選択してください。");
        if (!deal.value.unit) errors.value.push("数量を入力してください。");
        if (deal.value.unit < 1) errors.value.push("数量は1以上の数字を入力してください。");
        if (!deal.value.discount_rate) errors.value.push("値引率を入力してください。");
        if (deal.value.discount_rate < 1) errors.value.push("値引率は1以上の数字を入力してください。");
        const lotCheck = /^[a-zA-Z0-9 ]*$/;
        if (!deal.value.lot_number) {
          errors.value.push("ロット番号を入力してください。");
        } else if (!lotCheck.test(deal.value.lot_number)){
          errors.value.push("ロット番号は半角英数字で入力してください");
        }
        if (!expiresOn) {
          errors.value.push("使用期限を入力してください。");
        } else if (DateTime.now().toFormat("yyyy-MM-dd") > expiresOn) {
          errors.value.push("使用期限が過ぎている医薬品は出品できません。");
        }
        if (deal.value.unpacked === null) errors.value.push("開封 / 未開封を選択してください。");
        if (errors.value.length) return;

        const req_params = {
          unit: deal.value.unit,
          discount_rate: discountRate.value,
          unpacked: deal.value.unpacked,
          expires_on: expiresOn,
          lot_number: deal.value.lot_number,
          comment: deal.value.comment,
          group_ids: selectedGroupIds.value.join(","),
          price_basis: priceBasis.value,
          trade_unit_price: tradeUnitPrice.value,
          price: price.value,
          description: deal.value.description,
          quantity_unit: deal.value.quantity_unit,
        };

        await httpMedshare.patch(dealUrl.value, req_params);
        await router.push({ name: "取引詳細", params: { id: dealId.toString() } });
      } catch (e) {
        if (e.response?.data?.errors) {
          errors.value = e.response.data.errors;
        } else {
          errors.value = [e.message];
          throw e;
        }
      }
    }

    const truncatedUnitName = computed(() => {
      const maxLength = 10; // 省略する文字数の閾値を設定
      if (deal.value?.dealable_item.unit_name && deal.value?.dealable_item.unit_name.length > maxLength) {
        return deal.value?.dealable_item.unit_name.substring(0, maxLength) + '...';
      }
      return deal.value?.dealable_item.unit_name ? deal.value?.dealable_item.unit_name : '';
    });

    const tooltipText = computed(() => {
      const maxLength = 10; // 省略する文字数の閾値を設定
      // 文字数が10文字を超える場合のみツールチップを表示
      if (deal.value?.dealable_item?.unit_name) {
        return deal.value?.dealable_item.unit_name.length > maxLength ? `円 / ${deal.value?.dealable_item.unit_name}` : '';
      }
      return '';
    });

    const minPrice = computed(() => {
      let coefficient = 1
      if (deal.value?.dealable_item?.min_drug_price_coefficient) {
        coefficient = deal.value?.dealable_item.min_drug_price_coefficient;
      }
      if (deal.value?.dealable_item) {
        return parseFloat((deal.value?.dealable_item.price * coefficient).toFixed(2));
      }
      return 0;
    });

    watch(isChecked, (newValue) => {
      priceBasis.value = newValue ? 'trade_unit_price' : 'discount_rate';
    }); 

    watch(myPharmacyId, () => load());
    load();

    return {
      isFirstLoading,
      isLoading,
      errors,
      deal,
      expires_on,
      selectedGroupIds,
      discountRateTooltip,
      shouldDisableSaveButton,
      price,
      thisMonth,
      changePriceBasis,
      changeUnpacked,
      save,
      priceBasis,
      tradeUnitPrice,
      discountRate,
      updateTradeUnitPrice,
      updateDiscountRate,
      unitName,
      truncatedUnitName,
      tooltipText,
      minPrice,
      isChecked,
    };
  },
});
