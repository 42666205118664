import App from "@/App.vue";
import { createHttp } from "@/plugins/axios";
import router from "@/router";
import store from "@/store";
import CoreuiVue from "@coreui/vue";
import VueCompositionApi from "@vue/composition-api";
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { iconsSet as icons } from "./assets/icons/icons.js";

Vue.config.productionTip = false;

/* CoreUI */
Vue.use(CoreuiVue);

/* Vue Composition API */
Vue.use(VueCompositionApi);

/* vue-clipboard2 */
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

/* HTTP client */
Vue.prototype.$httpExternal = createHttp();

if (!process.env.VUE_APP_V2_API_CORE_HOST) throw "Set VUE_APP_V2_API_CORE_HOST in environment variables";
Vue.prototype.$httpCore = createHttp(process.env.VUE_APP_V2_API_CORE_HOST);

if (!process.env.VUE_APP_V2_API_MEDSHARE_HOST) throw "Set VUE_APP_V2_API_MEDSHARE_HOST in environment variables";
Vue.prototype.$httpMedshare = createHttp(process.env.VUE_APP_V2_API_MEDSHARE_HOST);

new Vue({
  router,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  icons,
  store,
  render: (h) => h(App),
}).$mount("#app");
