






















import { Deal } from "@/models/Deal";
import { computed, defineComponent } from "@vue/composition-api";
import StepProgress from "vue-step-progress";

export default defineComponent({
  components: { StepProgress },
  props: {
    deal: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const standard_steps = ["出品中", "取引中", "取引済み"];
    const cancel_steps = ["取引キャンセル"];

    const dealStep = computed(() => {
      switch ((props.deal as Deal).status) {
        case "OPEN":
          return 0;
        case "DEALING":
          return 1;
        case "CLOSE":
          return 2;
        case "CANCEL":
          return 1;
        default:
          return 0;
      }
    });

    return {
      standard_steps,
      cancel_steps,
      dealStep,
    };
  },
});
