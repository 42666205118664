












import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  setup() {
    return {
      core_url: process.env.VUE_APP_V2_APP_CORE_HOST,
      medorder_url: process.env.VUE_APP_V2_APP_MEDORDER_HOST,
    };
  },
});
