































import { readFiles } from "@/utils/file";
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  props: {
    message: {
      type: String,
      default: "ここにファイルを、ドラッグ アンド ドロップしてください。",
    },
    submitButtonName: {
      type: String,
      default: "Submit",
    },
  },
  setup(props, context) {
    const inputRef = ref<HTMLInputElement>();

    const isDragging = ref<boolean>(false);
    const files = ref<Array<File>>([]);

    function changeIsDragging(value: boolean): void {
      isDragging.value = value;
    }

    async function selectFiles(e: Event): Promise<void> {
      const fileList: FileList | null = await readFiles(e);
      if (fileList && fileList.length > 0) files.value = [...files.value, ...Array.from(fileList)];
    }

    function openFileDialog(): void {
      inputRef.value?.click();
    }

    function onDrop(event: DragEvent): void {
      isDragging.value = false;

      if (!event) return;
      if (!event.dataTransfer) return;
      if (event.dataTransfer.files.length === 0) return;

      files.value = [...files.value, ...event.dataTransfer.files];
    }

    function removeFile(index: number) {
      files.value.splice(index, 1);
    }

    function clearFiles() {
      files.value = [];
    }

    function onSubmit() {
      context.emit("submit-action", files.value);
      clearFiles();
    }

    return {
      inputRef,
      isDragging,
      files,
      changeIsDragging,
      onDrop,
      selectFiles,
      openFileDialog,
      removeFile,
      clearFiles,
      onSubmit,
    };
  },
});
