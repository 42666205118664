import { DateTime } from "luxon";

export class DealHistory {
  id!: number;
  message!: string;
  reason?: string;
  created_at!: string;
  updated_at!: string;
  created_at_datetime!: DateTime;
  updated_at_datetime!: DateTime;

  public constructor(init?: Partial<Record<string, unknown>>) {
    Object.assign(this, init);
    this.created_at_datetime = DateTime.fromISO(this.created_at);
    this.updated_at_datetime = DateTime.fromISO(this.updated_at);
  }

  get display_reason(): string {
    return this.reason || "-";
  }

  get display_created_at(): string {
    return this.created_at_datetime.toLocaleString(DateTime.DATETIME_SHORT) || "ERROR";
  }
}
