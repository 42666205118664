export class DealImageToken {
  token!: string;
  expires_in!: number;
  presigned_urls!: DealImagePresignedUrl[];

  public constructor(init?: Partial<Record<string, unknown>>) {
    Object.assign(this, init);
    if (init?.presigned_urls) {
      this.presigned_urls = (init?.presigned_urls as Array<Record<string, unknown>>).map(
        (presigned_url) => new DealImagePresignedUrl(presigned_url)
      );
    }
  }
}

export class DealImagePresignedUrl {
  presigned_url_get!: string;
  presigned_url_put!: string;
  object_name!: string;

  public constructor(init?: Partial<Record<string, unknown>>) {
    Object.assign(this, init);
  }
}
