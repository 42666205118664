




import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  props: {
    text: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "secondary",
    },
    size: {
      type: String,
      default: "sm",
    },
  },
  setup(props) {
    const tooltip = ref<Record<string, string>>({
      header: "クリップボードにコピーしました",
      content: props.text,
    });

    return {
      tooltip,
    };
  },
});
