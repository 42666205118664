


















































import { Deal } from "@/models/Deal";
import { computed, defineComponent } from "@vue/composition-api";
import { DateTime } from "luxon";
import StepProgress from "vue-step-progress";

export default defineComponent({
  components: { StepProgress },
  props: {
    deal: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const deal = computed(() => {
      return props.deal as Deal;
    })
    const expires_on = computed(() => DateTime.fromISO(deal.value.expires_on).toFormat("yyyy-MM"));

    const openPrice = computed( () => {
      return Math.round(tradeUnitPrice.value * deal.value.unit);
    });

    const unitName = computed(() => {
      return deal.value.dealable_item.unit_name ? "/" + deal.value.dealable_item.unit_name : "";
    })

    const tradeUnitPrice = computed(() => {
      // 出品中ではない場合
      if (deal.value.status !== "OPEN") {
        if (deal.value.trade_unit_price) {
          return deal.value.trade_unit_price;
        }
        return parseFloat((deal.value.price / deal.value.unit).toFixed(2));
      }

      // 出品中の場合で、計算基準が「単価」の場合
      if (deal.value.price_basis === "trade_unit_price") {
        return deal.value.trade_unit_price ? deal.value.trade_unit_price: 0;
      }

      // 出品中の場合で、計算基準が「値引率」かNULLの場合
      let coefficient = 1;
      if (deal.value.dealable_item.min_drug_price_coefficient) {
        coefficient = deal.value.dealable_item.min_drug_price_coefficient;
      }
      if (deal.value.dealable_item) {
        return parseFloat((deal.value.dealable_item.price * coefficient * (100 - deal.value.discount_rate) / 100).toFixed(2));
      }
      return 0;
    })

    const discountRate = computed(() => {
      // 出品中ではない場合
      if (deal.value.status !== "OPEN") {
        return deal.value.discount_rate;
      }

      // 出品中の場合で、計算基準が「値引率」かNULLの場合
      if (deal.value.price_basis === "discount_rate" || deal.value.price_basis === null) {
        return deal.value.discount_rate;
      }

      // 出品中の場合で、計算基準が「単価」の場合
      let coefficient = 1;
      if (deal.value.dealable_item.min_drug_price_coefficient) {
        coefficient = deal.value.dealable_item.min_drug_price_coefficient;
      }
      if (deal.value.dealable_item) {
        return Math.round((1 - (tradeUnitPrice.value / (deal.value.dealable_item.price * coefficient))) * 100);
      }
      return 0;
    })

    return {
      expires_on,
      openPrice,
      unitName,
      tradeUnitPrice,
      discountRate,
    };
  },
});
