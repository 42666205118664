export class DealableItem {
  id!: number;
  receipt_code!: string;
  name!: string;
  name_kana!: string;
  unit?: string;
  price!: number;
  drug_poison!: number;
  generic!: boolean;
  interim_period_on?: Date;
  disable!: boolean;
  expires_at?: Date;
  iryohoken_id!: number;
  iryohoken_row_id!: number;
  created_at!: Date;
  updated_at!: Date;
  min_drug_price_coefficient?: number | null;
  unit_name?: string | null;

  public constructor(init?: Partial<Record<string, unknown>>) {
    Object.assign(this, init);
  }
}
