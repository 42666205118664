






































































































































import SelectGroups from "@/components/groups/SelectGroups.vue";
import SelectDealableItems from "@/components/search/SelectDealableItems.vue";
import { DealableItem } from "@/models/DealableItem";
import { useHttpMedshare } from "@/plugins/axios";
import { useRouter } from "@/router/useRouter";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import { DateTime } from "luxon";
import Multiselect from "vue-multiselect";

export default defineComponent({
  components: { Multiselect, SelectDealableItems, SelectGroups },
  props: {
    dealable_item: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const router = useRouter();
    const httpMedshare = useHttpMedshare();

    const isLoading = ref<boolean>(true);
    const errors = ref<string[]>([]);

    const selectingPharmacyStore = useSelectingPharmacyStore();
    const myPharmacyId = selectingPharmacyStore.selectingPharmacyId;

    const selectedGroupIds = ref<number[]>([]);

    const dealableItemId = ref<number | null>(null);
    const dealableItem = ref<DealableItem | null>(null);
    if (props && props.dealable_item) {
      dealableItem.value = props.dealable_item as DealableItem;
      dealableItemId.value = dealableItem.value.id;
    }

    const unit = ref<number>(1);
    const priceBasis = ref<string>("discount_rate");
    const discount_rate = ref<number>(0);
    const tradeUnitPrice = ref<number>(0);
    const unpacked = ref<boolean | null>(null);
    const expires_on = ref<string | null>(null);
    const lot_number = ref<string | null>(null);
    const comment = ref<string | null>(null);
    const description = ref<string | null>(null);


    const discountRateTooltip = {
      html: true,
      content: "期限1年以上: 40%<br />半年〜1年: 50%<br />半年以内: 60％<br /><br />ジェネリックはさらに20％加えた率を推奨しております。",
    };

    const shouldDisableSaveButton = computed(() => !dealableItem.value);
    const price = computed(() => {
      return Math.round((tradeUnitPrice.value * unit.value));
    });
    const thisMonth = DateTime.now().toFormat("YYYY-MM");

    function changeUnpacked(value: boolean) {
      unpacked.value = value;
    }

    watch(dealableItem, (newValue: DealableItem | null) => {
      unit.value = 1
      discount_rate.value = 0

      let coefficient = 1
      if (newValue?.min_drug_price_coefficient) {
        coefficient = newValue.min_drug_price_coefficient;
      }

      if (newValue) {
        tradeUnitPrice.value = parseFloat((newValue.price * coefficient).toFixed(2));
      }
    });

    const updateTradeUnitPrice  = () => {
      let coefficient = 1
      if (dealableItem.value?.min_drug_price_coefficient) {
        coefficient = dealableItem.value.min_drug_price_coefficient;
      }
      // 小数第二位までとする
      if (dealableItem.value) {
        tradeUnitPrice.value = parseFloat((dealableItem.value.price * coefficient * (100 - discount_rate.value) / 100).toFixed(2));
      }
    }

    const updateDiscountRate  = () => {
      let coefficient = 1
      if (dealableItem.value?.min_drug_price_coefficient) {
        coefficient = dealableItem.value.min_drug_price_coefficient;
      }
      // 整数とする
      if (dealableItem.value) {
        discount_rate.value = Math.round((1 - (tradeUnitPrice.value / (dealableItem.value.price * coefficient))) * 100);
      }
    }

    async function save() {
      errors.value = [];

      try {
        if (dealableItem.value === null) return;

        const expiresOn = (() => {
          if (!expires_on.value) {
            return null;
          } else {
            return DateTime.fromFormat(expires_on.value, "yyyy-MM").endOf("month").toFormat("yyyy-MM-dd");
          }
        })();
        if (!dealableItem.value) errors.value.push("医薬品を選択してください。");
        if (!unit.value) errors.value.push("数量を入力してください。");
        if (unit.value < 1) errors.value.push("数量は1以上の数字を入力してください。");
        if (!discount_rate.value) errors.value.push("値引率を入力してください。");
        if (discount_rate.value < 1) errors.value.push("値引率は1以上の数字を入力してください。");
        const lotCheck = /^[a-zA-Z0-9 ]*$/;
        if (!lot_number.value) {
          errors.value.push("ロット番号を入力してください。");
        } else if (!lotCheck.test(lot_number.value)){
          errors.value.push("ロット番号は半角英数字で入力してください");
        }
        if (!expiresOn) {
          errors.value.push("使用期限を入力してください。");
        } else if (DateTime.now().toFormat("yyyy-MM-dd") > expiresOn) {
          errors.value.push("使用期限が過ぎている医薬品は出品できません。");
        }
        if (unpacked.value === null) errors.value.push("開封 / 未開封を選択してください。");
        if (errors.value.length) return;

        const req_params = {
          dealable_item_id: dealableItem.value.id,
          unit: unit.value,
          discount_rate: discount_rate.value,
          unpacked: unpacked.value,
          expires_on: expiresOn,
          lot_number: lot_number.value,
          comment: comment.value,
          group_ids: selectedGroupIds.value.join(","),
          price_basis: priceBasis.value,
          trade_unit_price: tradeUnitPrice.value,
          price: price.value,
          description: description.value,
          quantity_unit: dealableItem.value.unit,
        };

        const response = await httpMedshare.post(`/api/v2/pharmacy/pharmacies/${myPharmacyId.value}/deals`, req_params);
        const id = response.data.id;

        await router.push({ name: "取引詳細", params: { id: id.toString() } });
      } catch (e) {
        if (e.response?.data?.errors) {
          errors.value = e.response.data.errors;
        } else {
          errors.value = [e.message];
          throw e;
        }
      }
    }

    const truncatedUnitName = computed(() => {
      const maxLength = 10; // 省略する文字数の閾値を設定
      if (dealableItem.value?.unit_name && dealableItem.value.unit_name.length > maxLength) {
        return dealableItem.value.unit_name.substring(0, maxLength) + '...';
      }
      return dealableItem.value?.unit_name ? dealableItem.value.unit_name : '';
    });

    const tooltipText = computed(() => {
      const maxLength = 10; // 省略する文字数の閾値を設定
      // 文字数が10文字を超える場合のみツールチップを表示
      if (dealableItem.value?.unit_name) {
        return dealableItem.value.unit_name.length > maxLength ? `円 / ${dealableItem.value.unit_name}` : '';
      }
      return '';
    });

    const minPrice = computed(() => {
      let coefficient = 1
      if (dealableItem.value?.min_drug_price_coefficient) {
        coefficient = dealableItem.value.min_drug_price_coefficient;
      }
      if (dealableItem.value) {
        return parseFloat((dealableItem.value.price * coefficient).toFixed(2));
      }
      return 0;
    });

    const isChecked = ref(false);

    watch(isChecked, (newValue) => {
      priceBasis.value = newValue ? 'trade_unit_price' : 'discount_rate';
    }); 

    return {
      isLoading,
      errors,
      selectedGroupIds,
      dealableItemId,
      dealableItem,
      unit,
      priceBasis,
      discount_rate,
      tradeUnitPrice,
      unpacked,
      expires_on,
      lot_number,
      comment,
      discountRateTooltip,
      shouldDisableSaveButton,
      price,
      thisMonth,
      changeUnpacked,
      updateTradeUnitPrice,
      updateDiscountRate,
      save,
      truncatedUnitName,
      tooltipText,
      minPrice,
      isChecked,
      description,
    };
  },
});
