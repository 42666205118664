import { DateTime } from "luxon";

export class DealImage {
  id!: number;
  file_name_s3!: string;
  size!: number;
  file_type!: string;
  extension!: string;
  image_type!: string;
  signed_url?: string;
  created_at!: string;
  updated_at!: string;
  created_at_datetime!: DateTime;
  updated_at_datetime!: DateTime;

  public constructor(init?: Partial<Record<string, unknown>>) {
    Object.assign(this, init);
    this.created_at_datetime = DateTime.fromISO(this.created_at);
    this.updated_at_datetime = DateTime.fromISO(this.updated_at);
  }

  public static imageTypeFromKey(key: number | string): string {
    switch (key.toString()) {
      case "0":
        return "包装";
      case "1":
        return "内容物";
      case "2":
        return "その他";
      default:
        return "エラー";
    }
  }
}
