// ref. https://medium.com/js-dojo/watch-vue-route-params-query-with-composition-api-97b3c8c402e

import Vue from "vue";
import VueRouter from "vue-router";
import { computed, ComputedRef } from "@vue/composition-api";

const routerParams = Vue.observable({
  pharmacyId: 0,
});

export function provideRoutePharmacyId(router: VueRouter): void {
  router.afterEach((route) => {
    const strPharmacyId = route.params.pharmacyId;
    if (strPharmacyId) {
      routerParams.pharmacyId = parseInt(strPharmacyId, 10);
    } else {
      routerParams.pharmacyId = 0;
    }
  });
}

export function useRoutePharmacyId(): ComputedRef<number> {
  return computed(() => routerParams.pharmacyId);
}
