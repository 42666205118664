import { computed } from "@vue/composition-api";
import { AxiosStatic } from "axios";

import { StoreItem } from "@/store/utils/StoreItem";
import { StoreActionFetchStateBase, initStoreActionFetchBaseState, fetchBase } from "@/store/actions/createActionFetchBase";

export interface StoreActionFetchItemParams {
  ids: number;
}

interface StoreActionFetchItemState<T extends StoreItem> extends StoreActionFetchStateBase {
  itemMap: Map<number, T>;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function initStoreActionFetchItemState<T>() {
  return {
    ...initStoreActionFetchBaseState(),
    itemMap: new Map<number, T>(),
  };
}

export class ActionFetchItemBuilder<T extends StoreItem> {
  private state: StoreActionFetchItemState<T>;
  private httpClient: AxiosStatic;
  private createUrl: (ids: number | number[]) => string;
  private paramIdsName = "ids";

  private afterReceiveItems: ((items: T[]) => void | Promise<void>) | null = null;
  private afterEndFetch: ((items: T[]) => void | Promise<void>) | null = null;

  public constructor(state: StoreActionFetchItemState<T>, httpClient: AxiosStatic, createUrl: (ids: number | number[]) => string) {
    this.state = state;
    this.httpClient = httpClient;
    this.createUrl = createUrl;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public addAfterReceiveItems(afterReceiveItems: (items: T[]) => void | Promise<void>) {
    this.afterReceiveItems = afterReceiveItems;
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public build() {
    const state = this.state;
    const httpClient = this.httpClient;
    const createUrl = this.createUrl;
    const paramIdsName = this.paramIdsName;
    const afterReceiveItems = this.afterReceiveItems;
    const afterEndFetch = this.afterEndFetch;

    async function fetch(ids: number | number[], force?: boolean) {
      return fetchBase(
        ids,
        force || false,
        state,
        httpClient,
        () => {
          return createUrl(ids);
        },
        paramIdsName,
        () => {
          return state.itemMap;
        },
        (map, items) => {
          for (const item of items) {
            map.set(item.id, item);
          }
        },
        afterReceiveItems,
        afterEndFetch
      );
    }

    function get(id: number) {
      return state.itemMap.get(id) || null;
    }

    const isFetching = computed(() => {
      return state.isFetching;
    });

    return {
      fetch,
      get,
      isFetching,
    };
  }
}
