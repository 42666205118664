














// SelectPharmacyTable.vue で選択した薬局を、消去する画面。
// 開発用途でのみ使用する想定。

import { useRouter } from "@/router/useRouter";
import { defineComponent } from "@vue/composition-api";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";

export default defineComponent({
  name: "ResetPharmacy",
  setup() {
    const router = useRouter();
    const selectingPharmacyStore = useSelectingPharmacyStore();

    selectingPharmacyStore.removeSelectingPharmacy();
    router.push("/select-pharmacy");

    return {};
  },
});
