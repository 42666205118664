import { SetupContext, inject, provide } from "@vue/composition-api";

// 返り値をどう書けば良いのか調べきれなかったので、いったん disable する
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function createStoreProvider<S>(storeCreator: (context: SetupContext) => S, storeName: string) {
  const StoreSymbol = Symbol(storeName);

  function provideStore(context: SetupContext) {
    const createdStore = storeCreator(context);
    provide(StoreSymbol, createdStore);
  }

  function useStore() {
    const store = inject<S>(StoreSymbol);
    if (!store) {
      throw `${storeName} isn't provided.`;
    }
    return store;
  }

  return {
    provideStore,
    useStore,
  };
}
