import { convert } from "encoding-japanese";

export async function readFiles(e: Event): Promise<FileList | null> {
  if (!e.target) return null;

  const target = e.target as HTMLInputElement;
  if (!target.files || target.files.length === 0) return null;

  return target.files;
}

export async function readFile(file: File): Promise<string> {
  const arrayBuffer = await file.arrayBuffer();
  const uint8ArrayBuffer = new Uint8Array(arrayBuffer);
  return convert(uint8ArrayBuffer, {
    from: "AUTO",
    to: "UNICODE",
    type: "string",
  });
}
