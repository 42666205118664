export class Group {
  id!: number;
  name!: string;
  created_at!: string;
  updated_at!: string;

  public constructor(init?: Partial<Record<string, unknown>>) {
    Object.assign(this, init);
  }
}
