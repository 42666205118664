































































































import DatetimeSpan from "@/components/shared/datetime/DatetimeSpan.vue";
import { useSelectingPharmacyStore } from "@/store/selectingPharmacyStore";
import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
  components: { DatetimeSpan },
  props: {
    deal: {
      type: Object,
      required: true,
    },
    printArea: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const selectingPharmacyStore = useSelectingPharmacyStore();
    const myPharmacyId = selectingPharmacyStore.selectingPharmacyId;
    const myPharmacy = computed(() => {
      if (props.deal.seller_pharmacy_id === myPharmacyId.value) {
        return props.deal.seller_pharmacy;
      } else {
        return props.deal.buyer_pharmacy;
      }
    });
    const dealPharmacy = computed(() => {
      if (props.deal.seller_pharmacy_id === myPharmacyId.value) {
        return props.deal.buyer_pharmacy;
      } else {
        return props.deal.seller_pharmacy;
      }
    });
    const dealStatusType = computed(() => {
      if (props.deal.seller_pharmacy_id === myPharmacyId.value) {
        return "販売";
      } else {
        return "購入";
      }
    });

    return {
      myPharmacy,
      dealPharmacy,
      dealStatusType,
    };
  },
});
